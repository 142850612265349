<template>
    <BaseSection section-width="content">
        <template #default>
            <BaseTable
                v-if="table && columns && table.rows"
                :columns="columns"
                :rows="table.rows"
            />
        </template>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    table: {
        type: Object,
        default: () => {}
    }
});

const columns = computed(() => {
    if (!props.table || !props.table.columns) {
        return [];
    }

    return props.table.columns.map((column) => column.heading);
});
</script>
