<template>
    <BaseSection 
        section-width="content"
        class="section--accordion"
    >
        <template
            v-if="title"
            #title
        >
            {{ title }}
        </template>

        <template #default>
            <BaseAccordion :items="accordion" />
        </template>
    </BaseSection>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: null,
    },
    accordion: {
        type: Array,
        default: () => [],
    },
});
</script>

<style lang="less">
.section--accordion {
    .section__title {
        .typography-h4();
    }

    .section__header + .section__body {
        margin-top: var(--spacing-sm);
    }

    @media @q-md-min {
        .section__header + .section__body {
            margin-top: var(--spacing-md);
        }
    }
}
</style>
