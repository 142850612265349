<template>
    <div
        v-show="pageIndex === form.activePage"
        class="formie-page"
        :data-page-index="pageIndex"
    >
        <slot
            name="pageHeader"
            :page-eader="page.name"
            :display="form.settings.displayCurrentPageTitle"
        >
            <h4
                v-if="form.settings.displayCurrentPageTitle"
                class="formie-page__title"
            >
                {{ page.name }}
            </h4>
        </slot>

        <div
            v-for="(row, rowIndex) in page.rows"
            :key="rowIndex"
            class="formie-page__row"
        >
            <div
                v-for="(field, fieldIndex) in row.fields"
                :key="fieldIndex"
                class="formie-page__row__field"
                :class="getConditionalClass(field, form.data)"
                v-bind="getFieldBindings(field)"
            >
                <component
                    :is="components[`Input${field.displayName}`]"
                    v-model="form.data[field.handle]"
                    :field="field"
                />
            </div>
        </div>

        <div
            class="formie-page__navigation"
            :class="buttonsPosition"
        >
            <component
                :is="formie.getComponent('button')"
                v-if="page.settings.showBackButton && form.activePage > 0"
                class="formie-page__navigation__button"
                :disabled="form.loading"
                :class="{ loading: form.loading }"
                type="button"
                @click.prevent="
                    useFormStore().setActivePage(form.handle, form.activePage - 1)
                "
            >
                {{ page.settings.backButtonLabel }}
            </component>
            <component
                :is="formie.getComponent('button')"
                class="formie-page__navigation__button"
                :disabled="form.loading"
                :class="{ loading: form.loading }"
                type="submit"
            >
                {{ page.settings.submitButtonLabel }}
            </component>
        </div>
    </div>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent, inject } from 'vue';
import { useConditional } from '../composables/conditionals.js';
import { useField } from '../composables/field.js';
import { useFormStore } from '../store/form.js';

const formie = inject('formie');

const components = {
    InputSingleLineText: defineAsyncComponent(() => import('./inputs/InputSingleLineText.vue')),
    InputSection: defineAsyncComponent(() => import('./inputs/InputSection.vue')),
    InputRadio: defineAsyncComponent(() => import('./inputs/InputRadio.vue')),
    InputPhone: defineAsyncComponent(() => import('./inputs/InputPhone.vue')),
    InputNumber: defineAsyncComponent(() => import('./inputs/InputNumber.vue')),
    InputName: defineAsyncComponent(() => import('./inputs/InputName.vue')),
    InputMultiLineText: defineAsyncComponent(() => import('./inputs/InputMultiLineText.vue')),
    InputHtml: defineAsyncComponent(() => import('./inputs/InputHtml.vue')),
    InputHidden: defineAsyncComponent(() => import('./inputs/InputHidden.vue')),
    InputHeading: defineAsyncComponent(() => import('./inputs/InputHeading.vue')),
    InputFileUpload: defineAsyncComponent(() => import('./inputs/InputFileUpload.vue')),
    InputEmail: defineAsyncComponent(() => import('./inputs/InputEmail.vue')),
    InputDropdown: defineAsyncComponent(() => import('./inputs/InputDropdown.vue')),
    InputDate: defineAsyncComponent(() => import('./inputs/InputDate.vue')),
    InputCheckboxes: defineAsyncComponent(() => import('./inputs/InputCheckboxes.vue')),
    InputAgree: defineAsyncComponent(() => import('./inputs/InputAgree.vue')),
    InputAddress: defineAsyncComponent(() => import('./inputs/InputAddress.vue'))
};

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    page: {
        type: Object,
        default: () => {},
    },
    pageIndex: {
        type: Number,
        default: 0,
    },
});

// Props to refs
const { form, page, pageIndex } = toRefs(props);

// Initialize stores
const { getConditionalClass } = useConditional();
const { getFieldBindings } = useField();

// Get position of navigation buttons
const buttonsPosition = computed(() => {
    if (page.value.settings.buttonsPosition === 'left-right') {
        return 'justify-between';
    }

    if (page.value.settings.buttonsPosition === 'center') {
        return 'justify-center';
    }

    if (page.value.settings.buttonsPosition === 'right') {
        return 'justify-end';
    }

    return '';
});
</script>

<style lang="less">
.formie-page {
    .formie-page__title {
        margin-bottom: 2rem;
    }

    .formie-page__row {
        display: flex;
        flex-wrap: wrap;
        gap: var(--spacing-sm, .625rem);
    }

    .formie-page__row__field {
        flex-grow: 1;
        margin-bottom: var(--spacing-lg, 1rem);

        .formie-page__row:last-child .formie-page__row__field {
            margin-bottom: 0;
        }

        textarea {
            resize: none;
        }

        &.formie-field--hidden {
            display: none;
        }

        &.formie-field--visible {
            display: block;
        }

        [dn-form-input__errors] {
            border: 1px solid red;
        }
    }

    .formie-page__navigation {
        display: flex;
        gap: .5rem;
        margin-top: var(--spacing-md, 1rem);

        &.justify-between {
            justify-content: space-between;
        }

        &.justify-center {
            justify-content: center;
        }

        &.justify-end {
            justify-content: flex-end;
        }
    }

    .formie-page__navigation__button {
        margin-top: 0;

        &.loading {
            animation: loading 2s infinite;
        }

        @keyframes loading {
            0% {
                opacity: .8;
            }

            50% {
                opacity: .5;
            }

            100% {
                opacity: .8;
            }
        }
    }
}
</style>
