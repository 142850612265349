<template>
    <BaseSection
        class="section--form"
        section-width="content"
        data-scroll-id="form"
    >
        <template #default>
            <div v-if="formObject">
                <FormieForm
                    :form-handle="formObject.handle"
                    locale="nl"
                />
            </div>
        </template>
    </BaseSection>
</template>

<script setup>
import { FormieForm } from '~/dn-packages/formie-plugin';

const props = defineProps({
    form: {
        type: [Object, Array],
        required: true,
    },
});

const formObject = computed(() => {
    // check if form is array or object, if array return first item else return Object
    return Array.isArray(props.form) ? props.form[0] : props.form;
});

</script>
