<template>
    <BaseSection
        v-if="richText || !!$slots.default"
        section-width="content"
        :title="title"
    >
        <BaseRichText :content="richText" />

        <CraftLink
            v-if="callToAction && callToAction.url"
            :link="callToAction"
            class="content-block__call-to-action base-button--secondary"
            size="large"
            icon="arrow-right"
            appearance="button"
            :measure-gtm-cta="true"
        />
    </BaseSection>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: null
    },
    richText: {
        type: String,
        default: null
    },
    callToAction: {
        type: Object,
        default: null
    }
});
</script>
