<template>
    <div
        class="base-table"
    >
        <table class="base-table__table">
            <thead>
                <tr>
                    <th
                        v-for="(column, index) in columns"
                        :key="`column_${index}`"
                        class="base-table__table__header"
                    >
                        {{ column }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, rowIndex) in rows"
                    :key="`row_${rowIndex}`"
                    class="base-table__table__row"
                >
                    <td
                        v-for="(_, columnIndex) in columns"
                        :key="`cell_${rowIndex}_${columnIndex}`"
                        class="base-table__table__cell"
                    >
                        {{ row[columnIndex] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
defineProps({
    columns: {
        type: Array,
        default: () => []
    },
    rows: {
        type: Array,
        default: () => []
    }
});
</script>

<style lang="less" src="./BaseTable.less"></style>
