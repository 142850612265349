<template>
    <FeaturedEntriesSection
        :section="[
            'articles'
        ]"
        :featured-entries="featuredArticles"
        :featured-tags="featuredTags"
        :sort-order="resultsSortOrder"
        :sort-by="sortBy"
        :appearance="resultsAppearance"
    />
</template>

<script setup>
defineProps({
    featuredArticles: {
        type: Array,
        default: () => []
    },
    featuredTags: {
        type: Array,
        default: () => []
    },
    resultsSortOrder: {
        type: String,
        default: 'desc'
    },
    sortBy: {
        type: String,
        default: 'postDate'
    },
    resultsAppearance: {
        type: String,
        default: 'grid',
        validator: value => ['grid', 'list'].includes(value)
    }
});
</script>
