<template>
    <BaseSection section-width="content">
        <iframe 
            style="border-radius: 12px;"
            :src="`https://open.spotify.com/embed${path}?utm_source=generator`"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
        />
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    url: {
        type: String,
        default: null,
    },
});

const path = computed(() => {
    if (!props.url) {
        return null;
    }

    /** Extract `/track/{{id}}` or `/episode/{{id}}` from the url using regex, examples:
    * https://open.spotify.com/track/0bYg9bo50gSsH3LtXe2SQn?si=8e9e2e0e0e9e0e9e
    * https://open.spotify.com/episode/0bYg9bo50gSsH3LtXe2SQn?si=8e9e2e0e0e9e0e9e
    */
    const regex = /\/(track|episode)\/([a-zA-Z0-9]+)/;
    const matches = props.url.match(regex);

    if (!matches) {
        return null;
    }

    return matches[0];
});
</script>
