<template>
    <div class="brandstofmonitor">
        <div class="brandstofmonitor__header">
            <h2>Actuele brandstofprijzen</h2>

            <div
                v-if="mostRecentPrice"
                class="brandstofmonitor__current-price"
            >
                <h3>Diesel grootverbruik</h3>
                <h4>{{ mostRecentPrice[0] }}</h4>
                <span>Prijzen per {{ mostRecentPrice[1] }}. Prijzen excl. BTW.</span>
            </div>
        </div>

        <div class="brandstofmonitor__body">
            <h3>Actuele dieselprijzen in Nederland</h3>

            <BaseTable
                v-if="prices"
                class="brandstofmonitor__table"
                :columns="['Dieselprijs (EUR)', 'Datum']"
                :rows="prices"
            />
        </div>
    </div>
</template>

<script setup>
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import FuelPricesQuery from '~/graphql/queries/FuelPrices.graphql';

const response = await useAsyncQuery({
    query: FuelPricesQuery
});

const entries = toValue(response.data)?.entries;

const prices = computed(() => {
    return entries?.map((entry) => {
        return [
            `€ ${formatPrice(entry.dieselPrice)}`,
            formatDate(entry.date)
        ];
    });
});

const mostRecentPrice = computed(() => {
    return toValue(prices)[0];
});

function formatPrice(craftNumber) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
    return new Intl.NumberFormat('nl-NL', { maximumSignificantDigits: 4 }).format(craftNumber);
}

function formatDate(craftDate) {
    return format(new Date(craftDate), 'EEEE dd MMMM y', { locale: nl });
}
</script>

<style lang="less" src="./BrandstofMonitor.less"></style>
