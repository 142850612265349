<template>
    <DelayHydration>
        <slot name="before" />

        <template
            v-for="(contentBlock, index) in filteredContentBlocks"
            :key="`contentBlock_${index}`"
        >
            <component
                :is="map[contentBlock.__typename]"
                v-bind="contentBlock"
            />
        </template>

        <slot name="after" />
    </DelayHydration>
</template>

<script setup>
import { computed, resolveComponent } from 'vue';

const props = defineProps({
    contentBlocks: {
        type: Array,
        required: true,
        default: () => [],
    },
});

/* eslint-disable camelcase */
const map = {
    contentBlocks_accordion_BlockType: resolveComponent('ContentBlocksAccordion'),
    contentBlocks_form_BlockType: resolveComponent('ContentBlocksForm'),
    contentBlocks_images_BlockType: resolveComponent('ContentBlocksImages'),
    contentBlocks_quote_BlockType: resolveComponent('ContentBlocksQuote'),
    contentBlocks_richText_BlockType: resolveComponent('ContentBlocksText'),
    // contentBlocks_textVideo_BlockType: resolveComponent('ContentBlocksTextVideo'),
    // contentBlocks_textImage_BlockType: resolveComponent('ContentBlocksTextImage'),
    contentBlocks_video_BlockType: resolveComponent('ContentBlocksVideo'),
    contentBlocks_brandstofmonitor_BlockType: resolveComponent('ContentBlocksBrandstofmonitor'),
    contentBlocks_featuredBijeenkomsten_BlockType: resolveComponent('ContentBlocksFeaturedBijeenkomsten'),
    contentBlocks_featuredArticles_BlockType: resolveComponent('ContentBlocksFeaturedArticles'),
    contentBlocks_featuredInfoPages_BlockType: resolveComponent('ContentBlocksFeaturedInfoPages'),
    contentBlocks_featuredTools_BlockType: resolveComponent('ContentBlocksFeaturedTools'),
    contentBlocks_featuredTrainings_BlockType: resolveComponent('ContentBlocksFeaturedTrainings'),
    contentBlocks_featuredDossiers_BlockType: resolveComponent('ContentBlocksFeaturedDossiers'),
    contentBlocks_table_BlockType: resolveComponent('ContentBlocksTable'),
    contentBlocks_spotify_BlockType: resolveComponent('ContentBlocksSpotify'),
    contentBlocks_downloads_BlockType: resolveComponent('ContentBlocksDownloads'),
    contentBlocks_cookiebotDeclaration_BlockType: resolveComponent('ContentBlocksCookieDeclaration'),
};
/* eslint-enable camelcase */

const shouldRender = (contentBlock) => {
    if (!contentBlock) {
        return false;
    }

    if (!contentBlock.__typename || !map[contentBlock.__typename]) {
        return false;
    }

    return true;
};

const filteredContentBlocks = computed(() => {
    return props.contentBlocks.filter((contentBlock) => shouldRender(contentBlock));
});
</script>

<style lang="less">
.content-block__call-to-action {
    margin-top: var(--spacing-sm);

    @media @q-md-min {
        margin-top: var(--spacing-md);
    }
}
</style>
