<template>
    <CardList>
        <template v-for="entry in entries">
            <ArticleCard
                v-if="entry.sectionHandle === 'articles'"
                :key="entry.id"
                :entry="entry"
                :is-inline="true"
            />

            <AgendaCard
                v-if="entry.sectionHandle === 'agenda' && [entry.typeHandle === 'deelopleiding' || entry.typeHandle === 'bijeenkomst']"
                :key="entry.id"
                :entry="entry"
                :is-inline="true"
            />

            <DossierCard
                v-if="entry.typeHanlde === 'dossier'"
                :key="entry.id"
                :entry="entry"
                :is-inline="true"
            />

            <PageCard
                v-if="entry.sectionHandle === 'pages'"
                :key="entry.id"
                :entry="entry"
                :is-inline="true"
            />

            <ToolCard
                v-if="entry.sectionHandle === 'tools'"
                :key="entry.id"
                :entry="entry"
                :is-inline="true"
            />

            <TrainingCard
                v-if="entry.sectionHandle === 'trainings'"
                :key="entry.id"
                :entry="entry"
                :is-inline="true"
            />
        </template>
    </CardList>
</template>

<script setup>
defineProps({
    entries: {
        type: Array,
        default: () => []
    }
});
</script>

<style lang="less" src="./OverviewList.less"></style>
