<template>
    <BaseSection
        :section-width="appearance === 'grid' ? 'content-lg' : 'content'"
        class="section--featured-items"
    >
        <OverviewGrid
            v-if="appearance === 'grid'"
            :entries="[
                ...featuredEntries,
                ...allEntries
            ]"
        />

        <OverviewList
            v-if="appearance === 'list'"
            :entries="[
                ...featuredEntries,
                ...allEntries
            ]"
        />

        <div
            v-if="showLoadMoreButton"
            class="featured-items__load-more"
        >
            <BaseButton
                class="base-button--tertiary"
                size="large"
                @click="loadMoreEntries"
            >
                Toon meer
            </BaseButton>
        </div>
    </BaseSection>
</template>

<script setup>
import FeaturedQuery from '~/graphql/queries/FeaturedEntries.graphql';

const props = defineProps({
    section: {
        type: [Array],
        default: () => []
    },
    entryType: {
        type: [Array],
        default: () => []
    },
    featuredEntries: {
        type: Array,
        default: () => []
    },
    featuredTags: {
        type: Array,
        default: () => []
    },
    sortOrder: {
        type: String,
        default: 'desc'
    },
    sortBy: {
        type: String,
        default: 'postDate'
    },
    appearance: {
        type: String,
        default: 'grid',
        validator: value => ['grid', 'list'].includes(value)
    }
});

const allEntries = ref([]); // Array containing all (loaded more) entries
const perPage = 12;
const pageOffset = ref(0); // Offset for the next query

const featuredEntryIds = props.featuredEntries.map(entry => entry.id);
const featuredTagIds = props.featuredTags.map(tag => tag.id);
const orderBy = `${props.sortBy} ${props.sortOrder}`;

const variables = computed(() => {
    const vars = {
        tags: featuredTagIds,
        orderBy,
        offset: toValue(pageOffset) * perPage,
        limit: perPage
    };

    // Only add section param if it's set
    if (props.section && props.section.length > 0) {
        vars.section = props.section;
    }

    // Only add entry type param if it's set
    if (props.entryType && props.entryType.length > 0) {
        vars.type = props.entryType;
    }

    // Only show bijeenkomsten in the future
    if (props.entryType.includes('bijeenkomst')) {
        vars.windexDate = '>= today';
    }

    // Exclude featured entries from results to prevent duplicates
    if (featuredEntryIds.length > 0) {
        vars.id = ['not', ...featuredEntryIds];
    }

    return vars;
});

const response = featuredTagIds && featuredTagIds?.length > 0
    ? await useAsyncQuery({
        query: FeaturedQuery,
        variables: variables
    })
    : null;

const showLoadMoreButton = computed(() => {
    return response && toValue(response.data)?.entryCount > ((toValue(pageOffset) + 1) * perPage);
});

const loadMoreEntries = () => {
    pageOffset.value++;
};

watchEffect(() => {
    // If the user does follow dossiers,
    // merge the results of various requests in a single array
    allEntries.value = [
        ...toValue(allEntries),
        ... (response) ? toValue(response.data)?.entries ?? [] : []
    ];
});
</script>

<style lang="less" src="./FeaturedEntriesSection.less"></style>
