export const useConditional = () => {
    // Gets the class based on the rules
    const getConditionalClass = (field, formData) => {
        if (!field || !field.enableConditions || !field.conditions || !formData) {
            return '';
        }

        const conditional = JSON.parse(field.conditions);
        const rules = conditional.conditions;
        const showRule = conditional.showRule;
        const conditionRule = conditional.conditionRule;

        if (!rules.length) {
            return '';
        }

        const rulesMatchList = getMatchedRules(rules, formData);
        return getClass(rulesMatchList, showRule, conditionRule) || '';
    };

    return {
        getConditionalClass,
    };
};

const getMatchedRules = (rules, formData) => {
    const matches = rules.map((rule) => {
        const value = rule.value;

        // Rule.field is a string like {name} or {name.lastName}
        const strippedField = rule.field?.replace(/{|}/g, '');
        // First the {} are removed, then the field is split by the dot, and the value is retrieved from the formData
        const fieldValue =
            strippedField
                .split('.')
                .reduce((obj, prop) => obj[prop], formData);

        switch (rule.condition) {
        case '=':
            return fieldValue === value;
        case '!=':
            return fieldValue !== value;
        case '>':
            return fieldValue > value;
        case '<':
            return fieldValue < value;
        case 'contains':
            return fieldValue.includes(value);
        case 'startsWith':
            return fieldValue.startsWith(value);
        case 'endsWith':
            return fieldValue.endsWith(value);
        default:
            return false;
        }
    });

    return matches;
};

const getClass = (rulesResults, showRule, conditionRule) => {
    if (conditionRule === 'all') {
        if (
            showRule === 'show' &&
            rulesResults.every(rule => rule === true)
        ) {
            return 'formie-field--visible';
        }

        if (
            showRule === 'hide' &&
            rulesResults.every(rule => rule === true)
        ) {
            return 'formie-field--hidden';
        }
    }

    if (conditionRule === 'any') {
        if (showRule === 'show' && rulesResults.includes(true)) {
            return 'formie-field--visible';
        }

        if (showRule === 'hide' && rulesResults.includes(true)) {
            return 'formie-field--hidden';
        }
    }
};
