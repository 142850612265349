<template>
    <BaseSection section-width="content">
        <BaseRichText>
            <component
                :is="'script'"
                id="CookieDeclaration"
                src="https://consent.cookiebot.com/56ce2851-70eb-458b-aea3-f46a7a043a8e/cd.js"
                type="text/javascript"
                async
            />
        </BaseRichText>
    </BaseSection>
</template>
